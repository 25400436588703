<template>
  <div class="registration-confirmed-container thank-you-page">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container fluid>
      <v-card flat>
        <v-card-text>
          <CategoryTitle :category="category" />

          <v-alert type="success" v-if="registrationSucceded" outlined>
            {{ $t("register.confirmed.successMessage") }}
          </v-alert>
          <ResponseMessage :response="response" v-else-if="!loading" />
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="!isAuthenticated"
            large
            depressed
            color="primary"
            min-width="200"
            @click="handleLogin"
            >{{ $t("register.confirmed.doLogin") }}</v-btn
          >
          <v-btn
            v-else
            large
            depressed
            color="primary"
            min-width="200"
            :to="{ name: 'Home' }"
            >{{ $t("register.confirmed.goToHome") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.registration-confirmed-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import ResponseMessage from "@/components/ResponseMessage";

import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";

import { mapGetters } from "vuex";
import userService from "~/service/userService";

export default {
  name: "RegisterConfirmed",
  components: { CategoryTitle, ResponseMessage },
  mixins: [categoryMixins, loginMixins],
  data() {
    return {
      loading: true,
      email: null,
      token: null,
      registrationSucceded: false,
      response: {}
    };
  },
  ...mapGetters({
    isAuthenticated: "cart/isAuthenticated"
  }),
  methods: {
    async handleLogin() {
      let isLoggedIn = await this.doLogin("Home");
      if (isLoggedIn) {
        this.$router.push({
          name: "Home"
        });
      }
    },
    async confirmRegistration(email, token) {
      try {
        this.response = {};
        let res = await userService.confirmRegistration(email, token);

        if (res && res.status == 0) {
          this.registrationSucceded = true;
        } else {
          this.response = res;
        }
      } catch (err) {
        console.log(err);
        this.response = err;
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.email &&
      this.$route.query.token
    ) {
      this.login = this.$route.query.email.replace(" ", "+");
      this.token = this.$route.query.token;
      this.confirmRegistration(this.login, this.token);
    }
  }
};
</script>
